import { Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { GenerateODataFilter } from "../../../helpers/odataFunctions";
import { useUserServiceGetUser1, useUserServiceGetUser } from "../../../openapi/queries";

export const UserSelector: React.FC<SelectProps<number, DefaultOptionType>> = (props) => {
    const [value, setValue] = useState("");
    const [debouncedValue] = useDebounce(value, 1000);

    const { data: user } = useUserServiceGetUser1({
        key: props.value ?? 0,
    }, undefined, {enabled: !!props.value});

    const { data, isLoading } = useUserServiceGetUser({
        top: '10',
        filter: GenerateODataFilter([{ type: 'search', values: [debouncedValue], property: 'email,username', name: 'name' }]),
    }, undefined, { enabled: debouncedValue.length > 0 });

    return (
        <Select
            showSearch
            allowClear
            filterOption={false}
            onSearch={setValue}
            notFoundContent={isLoading ? <Spin size="small" /> : (value === "" ? "Type to search" : "No matches")}
            {...props}
            options={[{ label: user?.username, value: user?.id }, ...(data?.value.map(e => ({ label: e.username, value: e.id })) ?? [])].filter(e => e.value !== undefined)}
        />
    );
}