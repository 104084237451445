import { Alert, Button, Col, Descriptions, Dropdown, Image, Input, List, Modal, Row, Space, Spin, Tag, Timeline, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditModel, { ModelHistoryDetailsButton } from './EditModel';
import { useInternalClientServiceGetInternalClient1, useModelServiceGetModelApprovalHistory, useModelServiceGetModelQAimages, useModelServicePutModel } from '../../../openapi/queries';
import { MaterialService, MaterialViewDTO, ModelPackService, ModelPackViewDTO, ModelService, ModelViewDTO, ModifierService, ModifierViewDTO } from '../../../openapi/requests';
import { AssetApprovalStatus, AssetApprovalStatusOptions } from '../../../models/enums';
import { BASE_PATH } from '../../..';
import ReactTimeago from 'react-timeago';
const { Text } = Typography;

const ApproveModel: React.FC<{ models: ModelViewDTO[], isOpen: boolean, onClose: () => void, hideEditor?: boolean }> = ({ models, isOpen, onClose, hideEditor }) => {
    const [index, setIndex] = useState(0);
    const [comment, setComment] = useState("");
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    useEffect(() => {
        setIndex(0);
    }, [models]);

    const model = useMemo(() => {
        if (index < models.length) {
            return models[index];
        }
        return undefined;
    }, [index, models]);

    const { data: qaImages } = useModelServiceGetModelQAimages({ key: model?.id ?? 0 }, undefined, { enabled: isOpen && model !== undefined });
    const { data: qaHistory } = useModelServiceGetModelApprovalHistory({ key: model?.id ?? 0 }, undefined, { enabled: isOpen && model !== undefined });
    const { data: client } = useInternalClientServiceGetInternalClient1({ key: model?.clientId ?? 0 }, undefined, { enabled: isOpen && model !== undefined });
    const { mutateAsync, isPending } = useModelServicePutModel();

    useEffect(() => {
        if (isOpen) {
            setIndex(0);
            setComment("");
        }
    }, [models, isOpen]);

    const handleApprove = useCallback((enable: boolean) => {
        if (model === undefined) {
            return;
        }

        mutateAsync({
            key: model.id,
            requestBody: {
                ...model,
                isEnabled: enable ? enable : model.isEnabled,
                approved: true,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === models.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [comment, index, model, mutateAsync, models, onClose]);

    const handleReject = useCallback(() => {
        if (model === undefined) {
            return;
        }

        mutateAsync({
            key: model.id,
            requestBody: {
                ...model,
                approved: false,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === models.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [comment, index, model, mutateAsync, models, onClose]);

    const handleSkip = useCallback(() => {
        if (index + 1 === models.length) {
            onClose();
        } else {
            setIndex(index + 1);
            setComment("");
        }
    }, [index, models.length, onClose]);

    const handleEdit = useCallback(() => {
        setIsEditorOpen(true)
    }, []);

    const handleEditClose = useCallback(() => {
        if (model) {
            ModelService.getModel1(model.id)
                .then((e) => {
                    Object.assign(model, e);
                    setIsEditorOpen(false);
                });
        }
    }, [model]);

    const metadata = model?.metadata.map(e => ({ value: e.value, name: e.name, empty: (!e.value || e.value.toLowerCase() === "n/a" || /^\s/.test(e.value) )}));
    const missingMetadata = ["brand", "banner", "ean", "category"].filter(m => !model?.metadata.some(e => e.name === m));

    return (
        <>
            <Modal
                width={'90%'}
                open={isOpen}
                title={model?.title}
                onCancel={onClose}
                styles={{
                    footer: {
                        gap: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }
                }}
                footer={[
                    <Button key="back" disabled={isPending} danger type="primary" onClick={handleReject}>
                        Reject
                    </Button>,
                    <Space key="submit" >
                        <Dropdown.Button onClick={() => handleApprove(false)} menu={{ items: [{ key: 1, label: 'Approve and enable' }], onClick: () => handleApprove(true) }}>Approve</Dropdown.Button>
                    </Space>,
                    hideEditor === true ? null : <Button key="edit" disabled={isPending} type="default" onClick={handleEdit}>
                        Edit
                    </Button>,
                    <Button key="skip" disabled={isPending} type="link" onClick={handleSkip}>
                        Skip
                    </Button>,
                ]}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <p>Thumbnail:</p>
                        <Image src={BASE_PATH + model?.image} />
                        <p>QA Images:</p>
                        <Space>
                            {qaImages?.map(e => <Image src={BASE_PATH + e} />)}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Descriptions layout='horizontal' size='small' bordered column={1} title="Data" items={[
                            {
                                label: 'Status',
                                children: AssetApprovalStatusOptions[(model?.approvalStatus ?? 0) as AssetApprovalStatus]
                            },
                            {
                                label: 'Enabled',
                                children: model?.isEnabled ? 'True' : 'False'
                            },
                            {
                                label: 'Title',
                                children: model?.title
                            },
                            {
                                label: 'Filename',
                                children: `${client?.maxModelsPath}\\${model?.filename}`
                            },
                            {
                                label: 'Name',
                                children: model?.name
                            },
                            {
                                label: 'Tags',
                                children: model?.tags.map(e => <Tag>{e}</Tag>)
                            },
                            {
                                label: 'Meta data',
                                children: <>
                                    <Descriptions size='small' colon layout='horizontal' column={1} items={metadata?.map(e => ({ key: Math.random(), label: <Typography.Text mark={e.empty}>{e.name}</Typography.Text>, children: <Typography.Text mark={e.empty}>{e.value}</Typography.Text> }))} />
                                    {(missingMetadata.length !== 0) && <Alert message={`The following fields might be missing: ${missingMetadata.join(', ')}`} type="warning" />}
                                </>
                            },
                            {
                                label: 'Surfaces',
                                children: model?.surfaces.map(surface => (
                                    <Descriptions style={{ marginBottom: 20 }} labelStyle={{ width: 175 }} bordered size='small' colon layout='horizontal' column={1} items={[
                                        {
                                            label: 'Label',
                                            children: surface.label
                                        },
                                        {
                                            label: 'Name',
                                            children: surface.name
                                        },
                                        {
                                            label: 'material selection filter',
                                            children: <>
                                                {surface.materialSelectionFilter}
                                                <QueryValidator type='Material' value={surface.materialSelectionFilter} />
                                            </>
                                        },
                                        {
                                            label: 'Object selector',
                                            children: surface.objectSelector
                                        }
                                    ]} />
                                ))
                            },
                            {
                                label: 'Placement points',
                                children: model?.placementpoints.map(point => (
                                    <Descriptions style={{ marginBottom: 20 }} labelStyle={{ width: 175 }} bordered size='small' colon layout='horizontal' column={1} items={[
                                        {
                                            label: 'Label',
                                            children: point.label
                                        },
                                        {
                                            label: 'Name',
                                            children: point.name
                                        },
                                        {
                                            label: 'Required',
                                            children: point.isRequired.toString()
                                        },
                                        {
                                            label: 'Model selection filter',
                                            children: <>
                                                {point.modelSelectionFilter}
                                                <QueryValidator type={point.useModelpack ? 'Modelpack' : 'Model'} value={point.modelSelectionFilter} />
                                            </>
                                        },
                                        {
                                            label: 'Use modelpack',
                                            children: point.useModelpack.toString()
                                        },
                                        {
                                            label: 'Object selector',
                                            children: point.objectSelector
                                        }
                                    ]} />
                                ))
                            },
                            {
                                label: 'History',
                                children: <Timeline items={[
                                    {
                                        color: 'blue',
                                        children: (<>
                                            Model created <ReactTimeago date={model?.timestampCreate ?? ""} />
                                        </>)
                                    },
                                    ...(qaHistory || []).map(e => ({
                                        color: e.newStatus === AssetApprovalStatus.Approved ? 'green' : (e.newStatus === AssetApprovalStatus.Rejected ? 'red' : 'blue'),
                                        children: (<>
                                            Status changed from <b>{AssetApprovalStatusOptions[e.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusOptions[e.newStatus as AssetApprovalStatus]}</b> {' '}
                                            <ReactTimeago date={e.timestamp} />
                                            <ModelHistoryDetailsButton item={e} model={model} />
                                            {e.comment && <>
                                                <br />
                                                <Text code>{e.comment}</Text>
                                            </>}
                                        </>)
                                    }))]} />
                            },
                        ]} />
                        <Input.TextArea value={comment} placeholder='comments' title='reason' onChange={e => setComment(e.target.value)} />
                    </Col>
                </Row>
            </Modal>

            {(hideEditor !== true) && <EditModel model={model} isOpen={isEditorOpen} onClose={handleEditClose} />}
        </>
    );
};

const QueryValidator: React.FC<{ type: 'Model' | 'Modelpack' | 'Material' | 'Modifier', value: string }> = ({ type, value }) => {
    const [result, setResult] = useState<ModelViewDTO[] | ModelPackViewDTO[] | ModifierViewDTO[] | MaterialViewDTO[] | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showAssets, setShowAssets] = useState(false)

    const handleApiError = useCallback((reason: any) => {
        if (reason?.response?.data?.detail) {
            setError(reason.response.data.detail);
        } else {
            setError(JSON.stringify(reason?.response))
        }
    }, []);

    useEffect(() => {
        setError(undefined);
        setResult(undefined);
        setIsLoading(true);
        setSuccess(false);

        if (type === "Model") {
            ModelService.getModel(value).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
                setSuccess(true);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        } else if (type === "Modelpack") {
            ModelPackService.getModelPack(value).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
                setSuccess(true);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        } else if (type === "Material") {
            MaterialService.getMaterial(value).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
                setSuccess(true);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        } else if (type === "Modifier") {
            ModifierService.getModifier(value).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
                setSuccess(true);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        }
    }, [handleApiError, type, value]);

    return (
        <div>
            {isLoading && <Spin />}
            {error && <Alert type="error" message={error} showIcon />}
            {success && <Alert type={value === "" ? "warning" : "success"} message={<>{result?.length + " matches"} <Button onClick={() => setShowAssets(true)}>show</Button></>} showIcon />}

            <Modal title="Matches" open={showAssets} onOk={() => setShowAssets(false)} onCancel={() => setShowAssets(false)}>
                {(result !== undefined || isLoading) && <div style={{ maxHeight: 400, overflow: 'auto' }}>
                    <List<{ title: string, thumbnail: string }>
                        itemLayout="horizontal"
                        dataSource={result}
                        loading={isLoading}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta title={item.title} avatar={<Image width={50} src={BASE_PATH + item.thumbnail} />} />
                            </List.Item>
                        )}
                    />
                </div>
                }
            </Modal>
        </div>
    )
}

export default ApproveModel;