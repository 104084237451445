import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import ModelDetails from '../designer/ModelDetails';
import TemplateDetails from '../designer/TemplateDetails';
import AssetFilter from '../layout/AssetFilter';
import AssetList from '../layout/AssetList';
import Layout from '../layout/Layout';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import { useCutoutServiceGetCutoutFilter, useSearchAssetServiceGetSearchAsset } from '../../openapi/queries';
import { AssetSearchResultDTO } from '../../openapi/requests';
import { Filter } from '../../types/types';
import ModelpackDetails from '../designer/ModelpackDetails';
import MaterialDetails from '../designer/MaterialDetails';
import ModifierDetails from '../designer/ModifierDetails';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as Search2Icon } from '../../assets/icons/search2.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import './AssetSearch.scss';
import BottomDrawer from '../layout/BottomDrawer';
import { BASE_PATH } from '../..';
import { AnimatePresence, motion } from 'framer-motion';
import { AssetCartContext } from '../../contexts/AssetCartContext';
import AssetCart from './AssetCart';
import WorkflowAssetDetails from '../designer/WorkflowAssetDetails';
import { DialogContext } from '../../contexts/DialogContext';

interface Props {
    selection: AssetSearchResultDTO[];
    onAssetSelected: (asset: AssetSearchResultDTO) => void;
    onSearchProjectsAll: () => void;
    onSearchProjectsAny: () => void;
}

const AssetSearch: React.FC<Props> = ({ onAssetSelected, selection, onSearchProjectsAll, onSearchProjectsAny }) => {
    const {addToCart} = useContext(AssetCartContext);
    const { odataAssetFilters } = useContext(ConfigContext);
    const { error } = useContext(DialogContext);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [query, setQuery] = useState('');
    const [value] = useDebounce(query, 250);

    const [selectedAsset, setSelectedAsset] = useState<AssetSearchResultDTO | undefined>(undefined);
    const [showTemplateDetails, setShowTemplateDetails] = useState(false);
    const [showModelDetails, setShowModelDetails] = useState(false);
    const [showModelpackDetails, setShowModelpackDetails] = useState(false);
    const [showMaterialDetails, setShowMaterialDetails] = useState(false);
    const [showModifierDetails, setShowModifierDetails] = useState(false);
    const [showWorkflowDetails, setShowWorkflowDetails] = useState(false);

    const searchResult = useSearchAssetServiceGetSearchAsset({ q: value, filter: GenerateODataFilter([...odataAssetFilters, ...filters]) })

    const { data: brandMetadata } = useCutoutServiceGetCutoutFilter({ name: "brand", filter: GenerateODataFilter(odataAssetFilters) });
    const { data: rangeMetadata } = useCutoutServiceGetCutoutFilter({ name: "product_range", filter: GenerateODataFilter(odataAssetFilters) });
    const { data: productTypeMetadata } = useCutoutServiceGetCutoutFilter({ name: "product_type_primary", filter: GenerateODataFilter(odataAssetFilters) });
    const { data: categoryMetadata } = useCutoutServiceGetCutoutFilter({ name: "category", filter: GenerateODataFilter(odataAssetFilters) });

    const brands = useMemo(() => {
        return brandMetadata ?? [];
    }, [brandMetadata]);

    const ranges = useMemo(() => {
        return rangeMetadata ?? [];
    }, [rangeMetadata]);

    const productTypes = useMemo(() => {
        return productTypeMetadata ?? [];
    }, [productTypeMetadata]);

    const categories = useMemo(() => {
        return categoryMetadata ?? [];
    }, [categoryMetadata]);

    const handleClick = useCallback((item: AssetSearchResultDTO) => {
        setSelectedAsset(item);

        if (item.assetType === "scene") {
            setShowTemplateDetails(true);
        }
        if (item.assetType === "model") {
            setShowModelDetails(true);
        }
        if (item.assetType === "modelpack") {
            setShowModelpackDetails(true);
        }
        if (item.assetType === "material") {
            setShowMaterialDetails(true);
        }
        if (item.assetType === "modifier") {
            setShowModifierDetails(true);
        }
        if (item.assetType === "workflowassets") {
            setShowWorkflowDetails(true);
        }
    }, []);

    const handleAssetSelect = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, asset: AssetSearchResultDTO) => {
        e.preventDefault();
        e.stopPropagation();
        onAssetSelected(asset);
    }, [onAssetSelected]);

    const handleAssetAdd = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, asset: AssetSearchResultDTO) => {
        e.preventDefault();
        e.stopPropagation();
        addToCart(asset).catch(reason => {
            error(undefined, reason)
        })
    }, [addToCart, error]);

    const renderOverlay = useCallback((asset: AssetSearchResultDTO) => {
        return (
            <div className='buttons'>
                <button><InfoIcon /></button>
                <button onClick={e => handleAssetSelect(e, asset)}><Search2Icon /></button>
                <button onClick={e => handleAssetAdd(e, asset)}><CartIcon /></button>
            </div>
        )
    }, [handleAssetAdd, handleAssetSelect]);

    return (<Layout className='asset-search'>
        <header>
            <div>
                <div className='search-field'>
                    <SearchIcon />
                    <input type='search' value={query} onChange={e => setQuery(e.currentTarget.value)} placeholder='Type to search...' />
                </div>

                <AssetFilter filters={filters} onChange={e => setFilters(e)} isOpen={true} items={[]} properties={[
                    { name: 'Brand', property: 'metadata.brand', type: 'select', itemsOverride: brands },
                    { name: 'Range', property: 'metadata.product_range', type: 'select', itemsOverride: ranges },
                    { name: 'Type', property: 'metadata.product_type_primary', type: 'select', itemsOverride: productTypes },
                    { name: 'Category', property: 'metadata.category', type: 'select', itemsOverride: categories },
                ]} />
            </div>
        </header>
        <div className='search-page-container'>
            <AssetList assets={searchResult?.data?.value ?? []} displayMode='medium' onItemClick={handleClick} renderOverlay={renderOverlay} />
        </div>

        <AssetCart />

        <BottomDrawer open={selection.length > 0} size={220} modal={false} className='selection'>
            <div className='assets'>
                <AnimatePresence>
                    {selection.map(asset => <motion.div 
                        key={asset.name} 
                        initial={{ opacity: 0 }} 
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }} 
                        className='asset'>
                            <img src={BASE_PATH + asset.thumbnail} alt={asset.title} />
                            <p>{asset.title}</p>
                            <div className='buttons'>
                                <button onClick={e => handleAssetSelect(e, asset)}><DeleteIcon /></button>
                            </div>
                    </motion.div>)}
                </AnimatePresence>
            </div>
            <div className='submit'>
                <h1>Find projects</h1>
                <button onClick={onSearchProjectsAll}>Using all selected assets</button>
                <button onClick={onSearchProjectsAny}>Using any of the selected assets</button>
            </div>
        </BottomDrawer>

        <TemplateDetails open={showTemplateDetails} onClose={() => setShowTemplateDetails(false)} templateId={selectedAsset?.id} />
        <ModelDetails open={showModelDetails} onClose={() => setShowModelDetails(false)} modelId={selectedAsset?.id} />
        <ModelpackDetails open={showModelpackDetails} onClose={() => setShowModelpackDetails(false)} modelpackId={selectedAsset?.id} />
        <MaterialDetails open={showMaterialDetails} onClose={() => setShowMaterialDetails(false)} materialId={selectedAsset?.id} />
        <ModifierDetails open={showModifierDetails} onClose={() => setShowModifierDetails(false)} modifierId={selectedAsset?.id} />
        <WorkflowAssetDetails open={showWorkflowDetails} onClose={() => setShowWorkflowDetails(false)} asset={selectedAsset} />
    </Layout>)
};

export default AssetSearch;