import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Descriptions, Drawer, Empty, Form, Image, Input, message, Modal, Radio, Row, Segmented, Select, Space, Tag, Timeline, Typography } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { diff, flattenChangeset } from 'json-diff-ts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTimeago from 'react-timeago';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels, AssetApprovalStatusOptions, AssetSourceType } from '../../../models/enums';
import { useInternalClientServiceGetInternalClient, useInternalTagServiceGetInternalTag, useModelServiceGetModelApprovalHistory, useModelServiceGetModelQAimages, useModelServicePutModel } from '../../../openapi/queries';
import { AssetApprovalHistoryDTO, ModelViewDTO } from '../../../openapi/requests';
import { AssetSelector } from '../Inputs/AssetSelector';
import ImageFileUpload from '../Inputs/ImageFileUpload';
import { ModelpackSelector } from '../Inputs/ModelpackSelector';
import QueryField from '../Inputs/QueryField';
import { UserSelector } from '../Inputs/UserSelector';
import ApproveModel from './ApproveModel';
const { Text, Title } = Typography;

const EditModel: React.FC<{ model?: ModelViewDTO, isOpen: boolean, onClose: () => void }> = ({ model, isOpen, onClose }) => {
    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const { data: alltags } = useInternalTagServiceGetInternalTag({}, undefined, { enabled: isOpen });
    const { data: qaHistory } = useModelServiceGetModelApprovalHistory({ key: model?.id ?? 0 }, undefined, { enabled: isOpen && model !== undefined });
    const { data: qaImages } = useModelServiceGetModelQAimages({ key: model?.id ?? 0 }, undefined, { enabled: isOpen && model !== undefined });
    const { mutateAsync, isPending } = useModelServicePutModel();
    const [approvalOpen, setApprovalOpen] = useState(false);

    const tags = useMemo(() => {
        if (alltags) {
            const tags = Array.from(new Set(alltags.value.map(e => e.value)));
            tags.sort();
            return tags;
        }
        return [];
    }, [alltags]);

    const [form] = Form.useForm<ModelViewDTO>();
    const [messageApi, contextHolder] = message.useMessage();

    const clientId = Form.useWatch('clientId', form);
    const filename = Form.useWatch('filename', form);
    const source = Form.useWatch('sourceType', form);
    const sourcePath = Form.useWatch('sourcePath', form);

    const [modelImage, setModelImage] = useState("")

    const assetFilePath = useMemo(() => {
        if (clients) {
            let client = clients.value.find(e => e.id === clientId);
            if (client) {
                return client.maxModelsPath + "\\" + filename;
            }
        }
        return "";
    }, [clientId, clients, filename]);

    const onSubmit = () => {
        form.submit();
    }

    const getThumbnailPath = useCallback(() => {
        return `${BASE_PATH}/model/${model?.id}/thumbnail?rnd=` + Math.floor(Math.random() * 999999999)
    }, [model]);

    const onUpload = (info: UploadChangeParam) => {
        if (model) {
            setModelImage(getThumbnailPath())
        }
    }

    const onFinish = () => {
        if (model && model.id) {

            const values = form.getFieldsValue(true) as ModelViewDTO;

            mutateAsync({ key: model.id, requestBody: values })
                .then(() => {
                    messageApi.success("Model updated");
                    onClose();
                }).catch(reason => {
                    messageApi.error(JSON.stringify(reason));
                });
        }
    };

    useEffect(() => {
        if (model) {
            setModelImage(getThumbnailPath());
            form.setFieldsValue({...model, metadata: [...model.metadata].sort((a,b) => a.name.localeCompare(b.name))});
        }
    }, [model, form, getThumbnailPath]);

    return (
        <>
            {contextHolder}

            <Drawer
                title={`Edit ${model?.title}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item label="Thumbnail">
                        <Space direction='vertical'>
                            <ImageFileUpload title="Upload thumbnail" action={`${BASE_PATH}/model/${model?.id}/image`} onUpload={onUpload} />
                            <Image placeholder="" preview={false} width={300} src={modelImage} />
                        </Space>
                    </Form.Item>

                    <Form.Item label="QA Images">
                        <Space wrap>
                            {qaImages?.map(img => <Image key={img} placeholder="" width={150} src={BASE_PATH + img} />)}
                            {qaImages?.length === 0 && <Empty />}
                        </Space>
                    </Form.Item>

                    <Form.Item name="isEnabled" valuePropName="checked">
                        <Checkbox>Enabled</Checkbox>
                    </Form.Item>

                    <Form.Item name="approvalStatus" label="Status">
                            <Segmented vertical
                                options={[
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Quote], value: AssetApprovalStatus.Quote, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Pending], value: AssetApprovalStatus.Pending, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.InProduction], value: AssetApprovalStatus.InProduction },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Commited], value: AssetApprovalStatus.Commited },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.New], value: AssetApprovalStatus.New },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Rejected], value: AssetApprovalStatus.Rejected },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.RejectedButModified], value: AssetApprovalStatus.RejectedButModified, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.RejectedPendingModification], value: AssetApprovalStatus.RejectedPendingModification, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Approved], value: AssetApprovalStatus.Approved },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.ApprovedButModified], value: AssetApprovalStatus.ApprovedButModified, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.ApprovedPendingModification], value: AssetApprovalStatus.ApprovedPendingModification, disabled: true },
                                ]}
                            />
                    </Form.Item>

                    <Form.Item label="History">
                        <Timeline items={[
                            {
                                color: 'blue',
                                children: (<>
                                    Model created <ReactTimeago date={model?.timestampCreate ?? ""} />
                                </>)
                            },
                            ...(qaHistory || []).map(e => ({
                                color: e.newStatus === AssetApprovalStatus.Approved ? 'green' : (e.newStatus === AssetApprovalStatus.Rejected ? 'red' : 'blue'),
                                children: (<>
                                    Status changed from <b>{AssetApprovalStatusOptions[e.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusOptions[e.newStatus as AssetApprovalStatus]}</b> {' '}
                                    <ReactTimeago date={e.timestamp} />
                                    <ModelHistoryDetailsButton item={e} model={model} />
                                    {e.comment && <>
                                        <br />
                                        <Text code>{e.comment}</Text>
                                    </>}
                                </>)
                            }))]} />
                            <Button type='primary' onClick={() => setApprovalOpen(true)}>Start Approval</Button>
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item name='producingArtistId' label="Artist">
                        <UserSelector />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='complexity' label="Complexity" >
                        <Radio.Group
                            options={[
                                { label: 'Simple', value: 0 },
                                { label: 'Complex', value: 5 },
                                { label: 'Very complex', value: 10 }
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='sourceType' label="Source" >
                        <Radio.Group
                            options={[
                                { label: 'Unknown', value: 0 },
                                { label: 'Existing CoD asset', value: 1 },
                                { label: 'Workflow', value: 2 },
                                { label: 'None', value: 3 },
                                { label: 'CAD File', value: 4 },
                                { label: 'Max File', value: 5 }
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>

                    {source === AssetSourceType.Workflow &&
                        <Form.Item label="Workflow asset">
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item name='sourcePath' style={{ flexGrow: 1 }} noStyle>
                                    <Input addonBefore="http://" />
                                </Form.Item>
                                <Button type="default" onClick={() => window.open(sourcePath ?? "")}>Open</Button>
                            </Space.Compact>
                        </Form.Item>}

                    {source === AssetSourceType.ExistingAsset &&
                        <Form.Item label="Source model" name="sourcePath">
                            <AssetSelector type='Model' />
                        </Form.Item>}

                    {(source === AssetSourceType.CADFile || source === AssetSourceType.MaxFile) &&
                        <Form.Item label="Source file folder">
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item name='sourcePath' style={{ flexGrow: 1 }} noStyle>
                                    <Input />
                                </Form.Item>
                                <CopyToClipboard text={sourcePath ?? ""}>
                                    <Button type="default">Copy path</Button>
                                </CopyToClipboard>
                            </Space.Compact>
                        </Form.Item>
                    }

                    <Form.Item name='notes' label="Notes">
                        <Input.TextArea rows={3} />
                    </Form.Item>

                    <Form.Item label="Filename">
                        <Space.Compact style={{ width: '100%' }}>
                            <Form.Item name='filename' style={{ flexGrow: 1 }} noStyle>
                                <Input />
                            </Form.Item>
                            <CopyToClipboard text={assetFilePath}>
                                <Button type="default">Copy full path</Button>
                            </CopyToClipboard>
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name='tags' label="Tags">
                        <Select mode='tags' options={tags.map(e => ({ label: e, value: e }))} />
                    </Form.Item>

                    <Form.Item label='Model packs'>
                        <Form.List name="modelpacks">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            
                                            <Form.Item
                                                style={{flexGrow: 1}}
                                                {...restField}
                                                name={[name, 'id']}
                                                rules={[{ required: true }]}
                                            >
                                                <ModelpackSelector style={{width: 350}} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add pack
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Metadata'>
                        <Form.List name="metadata">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="value" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Surfaces'>
                        <Form.List name="surfaces">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Label" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'objectSelector']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input readOnly disabled placeholder="object selector" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'materialSelectionFilter']}
                                            >
                                                <QueryField placeholder="material filter" type='Material' clientId={clientId} />
                                            </Form.Item>
                                        </Space>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Placement points'>
                        <Form.List name="placementpoints">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Label" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'objectSelector']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input readOnly disabled placeholder="object selector" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'modelSelectionFilter']}
                                            >
                                                <QueryField placeholder="model filter" type='Model' clientId={clientId} />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'isRequired']}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>Required</Checkbox>
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'useModelpack']}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>Use&nbsp;modelpack</Checkbox>
                                            </Form.Item>
                                        </Space>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>

            <ApproveModel isOpen={approvalOpen} onClose={() => setApprovalOpen(false)} models={model === undefined ? [] : [model]} hideEditor />
        </>

    );
};


export const ModelHistoryDetailsButton: React.FC<{ model?: ModelViewDTO, item: AssetApprovalHistoryDTO }> = ({ model, item }) => {

    const [isOpen, setIsOpen] = useState(false);

    const showModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleOk = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const oldModel = useMemo(() => {
        if (item.assetData && isOpen) {
            return JSON.parse(item.assetData) as ModelViewDTO;
        }
        return undefined;
    }, [isOpen, item.assetData]);

    const diffs = useMemo(() => {
        if (item.assetData && isOpen) {
            var oldData = JSON.parse(item.assetData) as ModelViewDTO;
            let changeset = flattenChangeset(diff(oldData, model, { surfaces: 'name', placementpoints: 'name', metadata: 'name' }));

            const ignoredProperties = ["$.approvalStatus", "$.timestampCreate"];
            changeset = changeset.filter(e => !ignoredProperties.includes(e.path));

            return changeset;
        }
        return undefined;
    }, [isOpen, item.assetData, model]);

    return (<>
        <Button size='small' type='link' onClick={showModal}>Details</Button>

        <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
            <p>
                Status changed from <b>{AssetApprovalStatusLabels[item.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusLabels[item.newStatus as AssetApprovalStatus]}</b> at {item.timestamp} by {item.username ?? "API"}
            </p>
            {item.comment && <p>
                Comment: <br />
                {item.comment}
            </p>}

            <Row>
                <Col span={12}>
                    <Title level={2}>Images</Title>
                    {item.images.map(e => <Image src={BASE_PATH + e} />)}


                    <Title level={2}>Data</Title>

                    {oldModel &&
                        <Descriptions layout='horizontal' size='small' bordered column={1} items={[
                            {
                                label: 'Title',
                                children: oldModel?.title
                            },
                            {
                                label: 'Filename',
                                children: oldModel?.filename
                            },
                            {
                                label: 'Name',
                                children: oldModel?.name
                            },
                            {
                                label: 'Tags',
                                children: oldModel?.tags?.map(e => <Tag>{e}</Tag>)
                            },
                            {
                                label: 'Meta data',
                                children: <Descriptions size='small' colon layout='horizontal' column={1} items={oldModel?.metadata?.map(e => ({ label: e.name, children: e.value }))} />
                            },
                            {
                                label: 'Surfaces',
                                children: oldModel?.surfaces?.map(surface => (
                                    <Descriptions style={{ marginBottom: 20 }} labelStyle={{ width: 175 }} bordered size='small' colon layout='horizontal' column={1} items={[
                                        {
                                            label: 'Label',
                                            children: surface.label
                                        },
                                        {
                                            label: 'Name',
                                            children: surface.name
                                        },
                                        {
                                            label: 'material selection filter',
                                            children: surface.materialSelectionFilter
                                        },
                                        {
                                            label: 'Object selector',
                                            children: surface.objectSelector
                                        }
                                    ]} />
                                ))
                            },
                            {
                                label: 'Placement points',
                                children: oldModel?.placementpoints?.map(point => (
                                    <Descriptions style={{ marginBottom: 20 }} labelStyle={{ width: 175 }} bordered size='small' colon layout='horizontal' column={1} items={[
                                        {
                                            label: 'Label',
                                            children: point.label
                                        },
                                        {
                                            label: 'Name',
                                            children: point.name
                                        },
                                        {
                                            label: 'Required',
                                            children: point.isRequired.toString()
                                        },
                                        {
                                            label: 'Model selection filter',
                                            children: point.modelSelectionFilter
                                        },
                                        {
                                            label: 'Use modelpack',
                                            children: point.useModelpack.toString()
                                        },
                                        {
                                            label: 'Object selector',
                                            children: point.objectSelector
                                        }
                                    ]} />
                                ))
                            },
                        ]} />
                    }


                </Col>
                <Col span={12}>
                    <Title>Diff with current data</Title>
                    {diffs?.map(e => <>
                        {e.type === "UPDATE" ? <Alert icon={<EditOutlined />} showIcon type='info' message={e.path} description={<><del>{JSON.stringify(e.oldValue)}</del> {JSON.stringify(e.value)}</>} /> : null}
                        {e.type === "ADD" ? <Alert icon={<PlusOutlined />} showIcon type='success' message={e.path} description={JSON.stringify(e.value)} /> : null}
                        {e.type === "REMOVE" ? <Alert icon={<DeleteOutlined />} showIcon type='error' message={e.path} description={<del>{JSON.stringify(e.value)}</del>} /> : null}
                    </>
                    )}
                </Col>
            </Row>
        </Modal>
    </>)
}

export default EditModel;